require('@fontsource/open-sans') 
const React = require('react')
const { ThemeProvider } = require('styled-components')
const useTheme = require('./src/components/theme/useTheme')


const theme = useTheme

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}